import {
  Backdrop,
  Box,
  Button,
  Container,
  Fade,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import qr1 from "../Green Meadows Website Design Assets/Rera QR Code/image 9.png";
import qr2 from "../Green Meadows Website Design Assets/Rera QR Code/image 10.png";
import "./Contact.css";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

const messageStyle = {
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  background: "#fff",
  padding: "20px",
  borderRadius: "10px",
};

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [nameError, setNameError] = useState("");
  const [numberError, setNumberError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchParams] = useSearchParams();
  const utmParams = {};
  for (const [key, value] of searchParams.entries()) {
    if (key.startsWith("utm_")) {
      utmParams[key] = value;
    }
  }

  const handleSuccessClose = () => setSuccessOpen(false);
  const handleErrorClose = () => setErrorOpen(false);

  const validateName = (name) => {
    if (!name) {
      setNameError("Name is required.");
      return false;
    } else {
      setNameError("");
      return true;
    }
  };

  const validateNumber = (number) => {
    if (!number) {
      setNumberError("Number is required.");
      return false;
    } else if (!/^[6-9][0-9]{9}$/.test(number)) {
      setNumberError("Invalid Mobile Number.");
      return false;
    } else {
      setNumberError("");
      return true;
    }
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setEmailError("Email is required.");
      return false;
    } else if (!emailPattern.test(email)) {
      setEmailError("Invalid email.");
      return false;
    } else {
      setEmailError("");
      return true;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isNameValid = validateName(name);
    const isNumberValid = validateNumber(number);
    const isEmailValid = validateEmail(email);

    if (!isNameValid || !isNumberValid || !isEmailValid) return;

    const TELEGRAM_BOT_TOKEN = "6833379735:AAH-CvbltEjMJUGaUHioWHp84JZvyf9_-io";
    const TELEGRAM_CHAT_ID = -4264340528;
    const text = `New contact recieved for Green Meadows:\n\nName: ${name}\nEmail: ${email}\nNumber: ${number}\n\n${JSON.stringify(utmParams)}\n\nLead from: Footer Contact`;
    const url = `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`;

    try {
      const response = await axios.post(url, {
        chat_id: TELEGRAM_CHAT_ID,
        text: text,
      });
      setSuccessOpen(true);
      setName('')
      setEmail('')
      setNumber('')
    } catch (error) {
      setErrorMessage(error);
      setErrorOpen(true);
    }
  };

  return (
    <Box
      sx={{
        background: "#000000",
      }}
      id="contact"
    >
      <Grid container py={5} rowSpacing={5}>
        <Grid item sm={12} md={6} sx={{ display: { xs: "flex", sm: "none" } }}>
          <form className="contact-form">
            <input
              type="text"
              placeholder="FULL NAME"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                validateName(e.target.value);
              }}
              onBlur={() => validateName(name)}
              style={{ marginBottom: "0px" }}
              required
            />
            {nameError && (
              <Typography color="error" fontFamily={"Inter"} fontSize={"14px"}>
                {nameError}
              </Typography>
            )}
            <input
              type="email"
              placeholder="EMAIL"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                validateEmail(e.target.value);
              }}
              onBlur={() => validateEmail(email)}
              style={{ marginBottom: "0px", marginTop: "60px" }}
              required
            />
            {emailError && (
              <Typography color="error" fontFamily={"Inter"} fontSize={"14px"}>
                {emailError}
              </Typography>
            )}
            <input
              type="tel"
              placeholder="MOBILE NUMBER"
              value={number}
              onChange={(e) => {
                setNumber(e.target.value);
                validateNumber(e.target.value);
              }}
              onBlur={() => validateNumber(number)}
              style={{ marginBottom: "0px", marginTop: "60px" }}
              required
            />
            {numberError && (
              <Typography
                color="error"
                mb={5}
                fontFamily={"Inter"}
                fontSize={"14px"}
              >
                {numberError}
              </Typography>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "60px",
              }}
            >
              <Button type="submit" onClick={handleSubmit}>
                Submit
              </Button>
            </Box>
          </form>
        </Grid>
        <Grid item sm={12} md={6} pb={5}>
          <Container className="contact" sx={{ width: "80%" }}>
            <Typography variant="h6" pb={2}>
              Address
            </Typography>
            <Typography>
              Survey No. 383 & Others, Village Pachane, Near Hinjawadi, Tal.
              Maval, Pune - 412115
            </Typography>
            <Typography variant="h6" mt={5} pb={2}>
              Contact Number:
            </Typography>

            <Typography>
              <a href="tel:9834031826">+91 9834 031 826</a>
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: 2,
              }}
              mt={5}
            >
              <img className="qr" src={qr1} alt="" width={"20%"} />
              <Box>
                <Typography variant="h6">RERA registration number :</Typography>
                <Typography>Phase 1 - P52100054816 </Typography>
                <Typography className="contact-link">
                  <a
                    href="https://maharera.maharashtra.gov.in/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://maharera.maharashtra.gov.in/
                  </a>
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: 2,
              }}
              mt={5}
            >
              <img className="qr" src={qr2} alt="" width={"20%"} />
              <Box>
                <Typography variant="h6">RERA registration number :</Typography>
                <Typography>Phase 2 - P52100053712</Typography>
                <Typography className="contact-link">
                  <a
                    href="https://maharera.maharashtra.gov.in/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://maharera.maharashtra.gov.in/
                  </a>
                </Typography>
              </Box>
            </Box>
          </Container>
        </Grid>
        <Grid item sm={12} md={6} sx={{ display: { xs: "none", sm: "flex" } }}>
          <form className="contact-form">
            <input
              type="text"
              placeholder="FULL NAME"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                validateName(e.target.value);
              }}
              onBlur={() => validateName(name)}
              style={{ marginBottom: "0px" }}
              required
            />
            {nameError && (
              <Typography color="error" fontFamily={"Inter"} fontSize={"14px"}>
                {nameError}
              </Typography>
            )}
            <input
              type="email"
              placeholder="EMAIL"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                validateEmail(e.target.value);
              }}
              onBlur={() => validateEmail(email)}
              style={{ marginBottom: "0px", marginTop: "60px" }}
              required
            />
            {emailError && (
              <Typography color="error" fontFamily={"Inter"} fontSize={"14px"}>
                {emailError}
              </Typography>
            )}
            <input
              type="tel"
              placeholder="MOBILE NUMBER"
              value={number}
              onChange={(e) => {
                setNumber(e.target.value);
                validateNumber(e.target.value);
              }}
              onBlur={() => validateNumber(number)}
              style={{ marginBottom: "0px", marginTop: "60px" }}
              required
            />
            {numberError && (
              <Typography
                color="error"
                mb={5}
                fontFamily={"Inter"}
                fontSize={"14px"}
              >
                {numberError}
              </Typography>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "60px",
              }}
            >
              <Button type="submit" onClick={handleSubmit}>
                Submit
              </Button>
            </Box>
          </form>
        </Grid>
      </Grid>

      {/* Modal for success */}
      <Modal
        id="enquiry-success-modal-contact-form"
        aria-labelledby="success-modal-title"
        aria-describedby="success-modal-description"
        open={successOpen}
        onClose={handleSuccessClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={successOpen}>
          <Box sx={messageStyle}>
            <Typography variant="h6" align="center" mb={2}>
              ✅Thanks for contacting us! Our team will reach out to you
              shortly.
            </Typography>
            <Button
              onClick={handleSuccessClose}
              fullWidth
              sx={{
                background: "#00813d",
                color: "#fff",
                "&:hover": { background: "#00811d" },
              }}
            >
              Close
            </Button>
          </Box>
        </Fade>
      </Modal>

      {/* Modal for error */}
      <Modal
        id="enquiry-error-modal"
        aria-labelledby="error-modal-title"
        aria-describedby="error-modal-description"
        open={errorOpen}
        onClose={handleErrorClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={errorOpen}>
          <Box sx={messageStyle}>
            <Typography variant="h6" align="center">
              ❌ {errorMessage}
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Button
                onClick={handleErrorClose}
                fullWidth
                sx={{
                  background: "#00813d",
                  color: "#fff",
                  "&:hover": { background: "#00811d" },
                }}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default Contact;
