import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { Link as ScrollLink } from "react-scroll";
import Logo from "../Green Meadows Website Design Assets/Logo/image 4.png";
import { Container } from "@mui/material";
import { NavLink } from "react-router-dom";

const drawerWidth = 240;
const navItems = [
  { label: "Home", id: "home", to: "/home" },
  { label: "About Us", id: "about", to: "/about" },
  { label: "Amenities", id: "amenities", to: "/amenities" },
  { label: "Layout", id: "layout", to: "/layout" },
  { label: "Location", id: "location", to: "/location" },
  { label: "Contact", id: "contact", to: "/contact" },
];

export const NavigationBar = ({ isScrolled }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle}>
      <List>
        {navItems.map((item) => (
          <ScrollLink
            key={item.id}
            to={item.id}
            smooth={true}
            duration={500}
            spy={true}
            offset={-130}
            onClick={handleDrawerToggle}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText primary={item.label} className="mobile-nav" />
              </ListItemButton>
            </ListItem>
          </ScrollLink>
        ))}
      </List>
    </Box>
  );

  return (
    <Box py={3} sx={{ position: "relative" }}>
      <Container>
        <AppBar
          component="nav"
          sx={{
            background: isScrolled ? "#fff" : "transparent",
            boxShadow: isScrolled ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "none",
            position: isScrolled ? "fixed" : "relative",
            transition: "background 0.3s, box-shadow 0.3s",
            width: "100%",
            padding: isScrolled ? { xs: "0", sm: "0 100px" } : null,
          }}
        >
          <Toolbar>
            <Box sx={{ width: { xs: "250px" } }}>
              <img src={Logo} alt="" width={"100%"} />
            </Box>
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                width: "100%",
                justifyContent: "end",
              }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                sx={{
                  mr: 2,
                  color: "rgb(0, 129, 61)",
                  background: "none",
                  "&:hover": {
                    background: "none",
                  },
                }}
              >
                <MenuIcon sx={{ fontSize: "40px" }} />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                justifyContent: "end",
                width: "100%",
              }}
            >
              {navItems.map((item) => (
                <ScrollLink
                  key={item.id}
                  to={item.id}
                  smooth={true}
                  duration={500}
                  spy={true}
                  offset={-100}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                      <Button
                        sx={{
                          fontFamily: "Inter",
                          color: "#000",
                          marginRight: "30px",
                        }}
                      >
                        {item.label}
                      </Button>
                </ScrollLink>
              ))}
            </Box>
          </Toolbar>
        </AppBar>
        <nav>
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
      </Container>
    </Box>
  );
};
