import {
  Box,
  Button,
  Container,
  Typography,
  Modal,
  Backdrop,
  Fade,
} from "@mui/material";
import React, { useState } from "react";
import location from "../Green Meadows Website Design Assets/Images/Rectangle 20.png";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setSubmitted } from "../redux/leadGenerationSlice";
import { useSearchParams } from "react-router-dom";

const style = {
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
};

const messageStyle = {
  ...style,
  width: 300,
  background: "#fff",
  padding: "20px",
  borderRadius: "10px",
};

const Location = () => {
  const [open, setOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [numberError, setNumberError] = useState("");
  const dispatch = useDispatch();
  const isSubmitted = useSelector((state) => state.submitted.isSubmitted);
  const [searchParams] = useSearchParams();
  const utmParams = {};
  for (const [key, value] of searchParams.entries()) {
    if (key.startsWith("utm_")) {
      utmParams[key] = value;
    }
  }

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSuccessClose = () => setSuccessOpen(false);
  const handleErrorClose = () => setErrorOpen(false);

  const validateName = (name) => {
    if (!name) {
      setNameError("Name is required.");
      return false;
    } else {
      setNameError("");
      return true;
    }
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setEmailError("Email is required.");
      return false;
    } else if (!emailPattern.test(email)) {
      setEmailError("Invalid email.");
      return false;
    } else {
      setEmailError("");
      return true;
    }
  };

  const validateNumber = (number) => {
    if (!number) {
      setNumberError("Number is required.");
      return false;
    } else if (!/^[6-9][0-9]{9}$/.test(number)) {
      setNumberError("Invalid Mobile Number.");
      return false;
    } else {
      setNumberError("");
      return true;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isNameValid = validateName(name);
    const isNumberValid = validateNumber(number);
    const isEmailValid = validateEmail(email);

    if (!isNameValid || !isNumberValid || !isEmailValid) return;

    const TELEGRAM_BOT_TOKEN = "6833379735:AAH-CvbltEjMJUGaUHioWHp84JZvyf9_-io";
    const TELEGRAM_CHAT_ID = -4264340528;
    const text = `New lead received for Green Meadows:\n\nName: ${name}\nEmail: ${email}\nNumber: ${number}\n\n${JSON.stringify(utmParams)}\n\nLead from: Google Map`;
    const url = `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`;

    try {
      await axios.post(url, {
        chat_id: TELEGRAM_CHAT_ID,
        text: text,
      });
      setOpen(false);
      setSuccessOpen(true);
      dispatch(setSubmitted());
    } catch (error) {
      setErrorMessage(error);
      setErrorOpen(true)
    }
  };

  return (
    <Container sx={{paddingTop: {xs: '100px', sm: 'none'}}}>
      <Box sx={{ position: "relative" }} id="location">
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="h4" className="title">
            Location
          </Typography>
        </Box>
        <Typography variant="h1" className="bg-text">
          Location
        </Typography>
      </Box>
      <Box
        className="amenities-text"
        sx={{
          textAlign: "center",
          width: {sx: '95%', sm: "80%"},
          margin: "auto",
          paddingBottom: "20px",
        }}
      >
        <Typography variant="h5">An Address in the Meadows</Typography>
        <Typography>
          Located just 15 minutes from Hinjawadi, Green Meadows is perfectly
          positioned for those seeking both convenience and calm. The upcoming
          metro promises to enhance connectivity to Green Meadows NA Plots and
          the ring road at just one minute will enhancing your commute to all
          over Pune and beyond. And in addition the Kasarsai backwaters offering
          natural beauty, this location truly has it all.
        </Typography>
      </Box>
      <Box mt={5} sx={{ position: "relative" }}>
        <Box
          sx={{
            filter: isSubmitted ? "null" : "blur(7px)",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src={location} alt="" width={"90%"} />
        </Box>
        {isSubmitted ? null : (
          <Button
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              background: "#00813D",
              borderRadius: "5px",
              color: "#fff",
              "&:hover": {
                background: "#00810D",
              },
              width: "200px",
            }}
            onClick={handleOpen}
          >
            Click to view
          </Button>
        )}
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={{ ...style, width: {xs: '350px', sm:'450px'} }}>
            <form className="contact-form" style={{ width: "100%" }}>
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Button onClick={handleClose} size="small">
                  x
                </Button>
              </Box>
              <Typography
                variant="h5"
                sx={{ fontFamily: "Kaisen Tokumin" }}
                mb={5}
              >
                BOOK A VISIT
              </Typography>
              <input
                type="text"
                placeholder="FULL NAME"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  validateName(e.target.value);
                }}
                onBlur={() => validateName(name)}
                style={{ marginBottom: "0px" }}
                required
              />
              {nameError && (
                <Typography
                  color="error"
                  fontFamily={"Inter"}
                  fontSize={"14px"}
                >
                  {nameError}
                </Typography>
              )}
              <input
                type="email"
                placeholder="EMAIL"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  validateEmail(e.target.value);
                }}
                onBlur={() => validateEmail(email)}
                style={{ marginBottom: "0px", marginTop: "50px" }}
                required
              />
              {emailError && (
                <Typography
                  color="error"
                  fontFamily={"Inter"}
                  fontSize={"14px"}
                >
                  {emailError}
                </Typography>
              )}
              <input
                type="tel"
                placeholder="MOBILE NUMBER"
                value={number}
                onChange={(e) => {
                  setNumber(e.target.value);
                  validateNumber(e.target.value);
                }}
                onBlur={() => validateNumber(number)}
                style={{ marginBottom: "0px", marginTop: "50px" }}
                required
              />
              {numberError && (
                <Typography
                  color="error"
                  mb={5}
                  fontFamily={"Inter"}
                  fontSize={"14px"}
                >
                  {numberError}
                </Typography>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "50px",
                }}
              >
                <Button type="submit" onClick={handleSubmit}>
                  Submit
                </Button>
              </Box>
            </form>
          </Box>
        </Fade>
      </Modal>

      {/* Modal for success */}
      <Modal
        id='enquiry-success-modal-location'
        aria-labelledby="success-modal-title"
        aria-describedby="success-modal-description"
        open={successOpen}
        onClose={handleSuccessClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={successOpen}>
          <Box sx={messageStyle}>
            <Typography variant="h6" align="center" mb={2}>
            ✅Thanks for contacting us! Our team will reach out to you shortly.
            </Typography>
            <Button
              onClick={handleSuccessClose}
              fullWidth
              sx={{
                background: "#00813d",
                color: "#fff",
                "&:hover": { background: "#00811d" },
              }}
            >
              Close
            </Button>
          </Box>
        </Fade>
      </Modal>

      {/* Modal for error */}
      <Modal
        id='enquiry-error-modal'
        aria-labelledby="error-modal-title"
        aria-describedby="error-modal-description"
        open={errorOpen}
        onClose={handleErrorClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={errorOpen}>
          <Box sx={messageStyle}>
            <Typography variant="h6" align="center">
            ❌ {errorMessage}
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Button onClick={handleErrorClose} fullWidth sx={{
                background: "#00813d",
                color: "#fff",
                "&:hover": { background: "#00811d" },
              }}>
                Close
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Container>
  );
};

export default Location;
