import React, { useState, useEffect } from 'react';
import { Box, CssBaseline } from '@mui/material';
import './App.css';
import HeroSection from './components/HeroSection';
import { NavigationBar } from './components/NavigationBar';
import Overview from './components/Overview';
import WhyUs from './components/WhyUs';
import Amenities from './components/Amenities';
import Gallery from './components/Gallery';
import MasterLayout from './components/MasterLayout';
import Location from './components/Location';
import AboutDevelopers from './components/AboutDevelopers';
import Contact from './components/Contact';
import EnquiryModal from './components/EnquiryModel';
import WhatsApp from './components/WhatsApp';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-KSWTPJ7Q'
}
TagManager.initialize(tagManagerArgs)

function App() {
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > window.innerHeight * 1.5) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <CssBaseline />
      <Box className='hero-image'>
        <NavigationBar isScrolled={isScrolled} />
        <HeroSection />
      </Box>
      <Box
        sx={{
          display: { xs: "none", sm: "block" },
          position: "fixed",
          right: "-1%",
          top: "50%",
          zIndex: 100,
        }}
      >
        <EnquiryModal />
      </Box>
      <Box
        sx={{
          display: { xs: "block", sm: "none" },
          position: "fixed",
          right: "0%",
          bottom: "0%",
          zIndex: 100,
          width: "100%",
        }}
      >
        <EnquiryModal />
      </Box>
      <Box sx={{ position: 'fixed', bottom: '10%', right: '1%', zIndex: 100 }}>
        <WhatsApp />
      </Box>
      <Overview />
      <WhyUs />
      <Amenities />
      <Gallery />
      <MasterLayout />
      <Location />
      <AboutDevelopers />
      <Contact />
    </div>
  );
}

export default App;