import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import './Amenities.css'
import pool from '../Green Meadows Website Design Assets/Icon/swimming-pool.png'
import petZone from '../Green Meadows Website Design Assets/Icon/pet-zone-point.png'
import prayer from '../Green Meadows Website Design Assets/Icon/meditation.png'
import cafe from '../Green Meadows Website Design Assets/Icon/coffee-cup.png'
import lawn from '../Green Meadows Website Design Assets/Icon/dance.png'
import office from '../Green Meadows Website Design Assets/Icon/work.png'
import court from '../Green Meadows Website Design Assets/Icon/court.png'
import gym from '../Green Meadows Website Design Assets/Icon/battle-rope.png'
import citizen from '../Green Meadows Website Design Assets/Icon/old-man.png'
import games from '../Green Meadows Website Design Assets/Icon/luck.png'
import playarea from '../Green Meadows Website Design Assets/Icon/playground.png'
import library from '../Green Meadows Website Design Assets/Icon/book.png'
import bonfire from '../Green Meadows Website Design Assets/Icon/bonfire.png'
import business from '../Green Meadows Website Design Assets/Icon/skyscrapper.png'
import restroom from '../Green Meadows Website Design Assets/Icon/bed.png'
import garden from '../Green Meadows Website Design Assets/Icon/park.png'
import banquet from '../Green Meadows Website Design Assets/Icon/hall.png'
import theatre from '../Green Meadows Website Design Assets/Icon/stadium.png'
import icon from '../Green Meadows Website Design Assets/Icon/icon-01.png'


const Amenities = () => {
  return (
    <Box
      id="amenities"
      sx={{
        background: "#F4F4F4",
        position: 'relative',
        paddingTop: {xs: '100px', sm: '0px'}
      }}
      pb={10}
    >
      <Container>
        <Box sx={{ position: "relative" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography variant="h4" className="title">
              Amenities
            </Typography>
          </Box>
          <Typography variant="h1" className="bg-text">
            Amenities
          </Typography>
        </Box>
        <Box className="amenities-text" sx={{
              textAlign: "center",
              width: {xs: "100%", sm: "80%"},
              margin: "auto",
              paddingBottom: "20px",
            }}>
          <Typography variant="h5">Luxury at Your Doorstep</Typography>
          <Typography>
            Discover a lifestyle of luxury at Green Meadows, where top-tier
            amenities blend seamlessly with nature, offering a modern living
            experience that prioritizes your comfort, leisure, and well-being.
          </Typography>
        </Box>
        <Grid container rowSpacing={5} columnSpacing={3} mt={5}>
            <Grid item xs={6} md={3}>
                <Box className='amenity'>
                    <img src={pool} alt="" width={'50%'}/>
                    <Typography>Swimming Pool</Typography>
                </Box>
            </Grid>
            <Grid item xs={6} md={3}>
                <Box className='amenity'>
                    <img src={petZone} alt="" width={'50%'}/>
                    <Typography>Pet Zone</Typography>
                </Box>
            </Grid>
            <Grid item xs={6} md={3}>
                <Box className='amenity'>
                    <img src={prayer} alt="" width={'50%'}/>
                    <Typography>Prayer / Meditation Area</Typography>
                </Box>
            </Grid>
            <Grid item xs={6} md={3}>
                <Box className='amenity'>
                    <img src={cafe} alt="" width={'50%'}/>
                    <Typography>Cafeteria</Typography>
                </Box>
            </Grid>

            <Grid item xs={6} md={3}>
                <Box className='amenity'>
                    <img src={lawn} alt="" width={'50%'}/>
                    <Typography>Party Lawn</Typography>
                </Box>
            </Grid>
            <Grid item xs={6} md={3}>
                <Box className='amenity'>
                    <img src={office} alt="" width={'50%'}/>
                    <Typography>Society Office</Typography>
                </Box>
            </Grid>
            <Grid item xs={6} md={3}>
                <Box className='amenity'>
                    <img src={court} alt="" width={'50%'}/>
                    <Typography>Multiplay Court</Typography>
                </Box>
            </Grid>
            <Grid item xs={6} md={3}>
                <Box className='amenity'>
                    <img src={gym} alt="" width={'50%'}/>
                    <Typography>Open Gym</Typography>
                </Box>
            </Grid>

            <Grid item xs={6} md={3}>
                <Box className='amenity'>
                    <img src={citizen} alt="" width={'50%'}/>
                    <Typography>Senior Citizen Court</Typography>
                </Box>
            </Grid>
            <Grid item xs={6} md={3}>
                <Box className='amenity'>
                    <img src={games} alt="" width={'50%'}/>
                    <Typography>Indoor Games</Typography>
                </Box>
            </Grid>
            <Grid item xs={6} md={3}>
                <Box className='amenity'>
                    <img src={playarea} alt="" width={'50%'}/>
                    <Typography>Kid's Play Area</Typography>
                </Box>
            </Grid>
            <Grid item xs={6} md={3}>
                <Box className='amenity'>
                    <img src={library} alt="" width={'50%'}/>
                    <Typography>Library</Typography>
                </Box>
            </Grid>

            <Grid item xs={6} md={3}>
                <Box className='amenity'>
                    <img src={bonfire} alt="" width={'50%'}/>
                    <Typography>Bonfire Area</Typography>
                </Box>
            </Grid>
            <Grid item xs={6} md={3}>
                <Box className='amenity'>
                    <img src={business} alt="" width={'50%'}/>
                    <Typography>Business Center</Typography>
                </Box>
            </Grid>
            <Grid item xs={6} md={3}>
                <Box className='amenity'>
                    <img src={restroom} alt="" width={'50%'}/>
                    <Typography>Driver's Restroom</Typography>
                </Box>
            </Grid>
            <Grid item xs={6} md={3}>
                <Box className='amenity'>
                    <img src={garden} alt="" width={'50%'}/>
                    <Typography>Aroma Garden</Typography>
                </Box>
            </Grid>

            <Grid item xs={6} md={3}>
                <Box className='amenity'>
                    <img src={banquet} alt="" width={'50%'}/>
                    <Typography>Banquet Hall</Typography>
                </Box>
            </Grid>
            <Grid item xs={6} md={3}>
                <Box className='amenity'>
                    <img src={theatre} alt="" width={'50%'}/>
                    <Typography>Amphitheatre</Typography>
                </Box>
            </Grid>
        </Grid>
        <Box className='icon-7'>
            <img src={icon} alt="" width={'10%'}/>
          </Box>
          <Box className='icon-8'>
            <img src={icon} alt="" width={'35%'}/>
          </Box>
      </Container>
    </Box>
  );
};

export default Amenities;
