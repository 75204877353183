import { configureStore } from '@reduxjs/toolkit';
import submittedReducer from './redux/leadGenerationSlice';

const store = configureStore({
  reducer: {
    submitted: submittedReducer,
  },
});

export default store;
