import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import './HeroSection.css'

const HeroSection = () => {
  return (
    <Container id='home'>
      <Typography className='hero-text'>Luxury Living in an Exclusive Haven</Typography>
      <Typography className='hero-text-2'>Near Kasarsai Dam</Typography>
      <Box sx={{display: 'flex', justifyContent: 'center'}}>
        <Typography className='hero-text-3'>Residential NA Plots & Row Houses</Typography>
      </Box>

    </Container>
  )
}

export default HeroSection
