import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSubmitted: false,
};

const submittedSlice = createSlice({
  name: 'submitted',
  initialState,
  reducers: {
    setSubmitted: (state) => {
      state.isSubmitted = true;
    },
    resetSubmitted: (state) => {
      state.isSubmitted = false;
    },
  },
});

export const { setSubmitted, resetSubmitted } = submittedSlice.actions;
export default submittedSlice.reducer;
