import React from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Box } from '@mui/material';

const WhatsApp = () => {
  const handleClick = () => {
    const url = `https://wa.me/${919834031826}?text=${encodeURIComponent('I want more information about Green Meadows NA Plots & Row Houses')}`;
    window.open(url, '_blank');
  };

  return (
    <Box sx={{background: '#fff', borderRadius: '50%', color: 'green', cursor: 'pointer', padding: '6px 6px 0px 6px'}}>
        <WhatsAppIcon className='whatsapp-btn-click' onClick={handleClick} sx={{fontSize: {xs: '40px', sm: '70px', md: '50px'}}}/>
    </Box>
  );
};

export default WhatsApp;
