import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import icon from "../Green Meadows Website Design Assets/Icon/icon-01.png";
import rainbow from "../Green Meadows Website Design Assets/Logo/image 6.png";
import nandan from "../Green Meadows Website Design Assets/Logo/image 8.png";

const AboutDevelopers = () => {
  return (
    <Box my={15}>
      <Grid container rowSpacing={10} mt={5}>
        <Grid item xs={12} sm={10}>
          <Box
            sx={{
              background: "rgba(47, 107, 69, 0.75)",
              padding: { xs: "100px 0", sm: '0' },
              position: 'relative'
            }}
          >
                <Box
                    sx={{
                      display: { xs: "flex", sm: "none" },
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                      top: "-15%",
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                  >
                    <img src={rainbow} alt="" width={"100%"} />
                  </Box>
            <Box sx={{ position: "relative" }}>
              <Box
                sx={{ display: "flex", justifyContent: "center" }}
                id="about"
              >
                <Typography
                  variant="h4"
                  className="title"
                  sx={{ color: "#fff", borderColor: "#fff" }}
                >
                  About Developers
                </Typography>
              </Box>
              <Typography
                variant="h1"
                className="bg-text"
                sx={{ color: "rgba(255, 255, 255, 0.025)" }}
              >
                Developers
              </Typography>
            </Box>
            <Box className="about-text">
              <Grid container>
                <Grid item xs={12} sm={8}>
                  <Typography
                    sx={{
                      width: "90%",
                      margin: "auto",
                      textAlign: { xs: "center", sm: "start" },
                    }}
                    pb={5}
                  >
                    Rainbow Housing, one of Pune's premier real estate
                    developers, has been changing the landscape of the city
                    since 2007. In the business of making apartments that
                    delight and satisfy its customer in every way, Rainbow
                    Housing has built a dedicated reputation for quality. For
                    its loyal customer today, this focus on quality represents a
                    far-reaching vision that shines through every Rainbow
                    property. We believe in reaching the highest level of
                    construction industry by focusing on Customer Satisfaction
                    and other deliverables.
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box
                    sx={{
                      display: {xs: 'none', sm: "flex"},
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={rainbow} alt="" width={"60%"} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <img src={icon} alt="" width={"150%"} style={{ opacity: "0.05" }} />
          </Box>
        </Grid>

        <Grid item xs={12} sm={2}>
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <img src={icon} alt="" width={"150%"} style={{ opacity: "0.05" }} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={10}>
          <Box
            sx={{
              background: "rgba(47, 107, 69, 0.75)",
              padding: { xs: "100px 0", sm: '0' },
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: { xs: "flex", sm: "none" },
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                top: "-15%",
                left: "50%",
                transform: "translateX(-50%)",
                border:'1px solid black'
              }}
            >
              <img src={nandan} alt="" width={"100%"} />
            </Box>
            <Box sx={{ position: "relative" }}>
              <Box
                sx={{ display: "flex", justifyContent: "center" }}
                id="about"
              >
                <Typography
                  variant="h4"
                  className="title"
                  sx={{ color: "#fff", borderColor: "#fff" }}
                >
                  About Developers
                </Typography>
              </Box>
              <Typography
                variant="h1"
                className="bg-text"
                sx={{ color: "rgba(255, 255, 255, 0.025)" }}
              >
                Developers
              </Typography>
            </Box>
            <Box className="about-text">
              <Grid container>
                <Grid item xs={12} sm={8}>
                  <Typography
                    sx={{
                      width: "90%",
                      margin: "auto",
                      textAlign: { xs: "center", sm: "start" },
                    }}
                    pb={5}
                  >
                    Nandan Buildcon is one of the leading construction business
                    conglomerates in Pune that has successfully completed over
                    34 projects. With more than a decade's excellence in
                    developing innovative and futuristic residential and
                    commercial landmarks, Nandan Buildcon is a name to reckon
                    with Excellence, Ethics and Empowerment.The group has
                    completed over 34 projects with saleable area of over 3 M
                    Sq.Ft.With 4 ongoing projects in Pune and Nashik, the
                    deliverable is -1.4 M Sq.Ft. The Company is a member of the
                    CREDAI & MBVA (Pune).
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box
                    sx={{
                      display: { xs: "none", sm: "flex" },
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={nandan} alt="" width={"60%"} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutDevelopers;
